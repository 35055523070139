<template>
    <div class="p-8 text-xs">
        
        <div class="rounded-2xl bg-white p-8">
            
            <div class="">
                    <div>
                        <div class="mt-4 grid grid-cols-12 gap-4">
                       <!-- <div class="col-span-3">
                           <div class="flex justify-center border border-gray-200">
                               <div class="w-40 h-40 ">
                                   <img :src="default_url + 'default.png'" class="w-40 h-40 object-cover " @click="$refs.foto_profile.click()"/>

                               </div>
                           </div>
                       </div> -->
                       <div class="col-span-12">

                          <ValidationObserver v-slot="{ invalid}" ref="form" >
                            <form @submit.prevent="postData()" class="w-full" >
                       
                                  <ValidationProvider rules="required" v-slot="{ errors }" name="Nama" vid="name" class="grid grid-cols-12 gap-4 items-center">
                            <label for="" class="block mb-2 col-span-2">Nama*</label>  
                            <div class="col-span-10">
                            <input
                                v-model="addData.name"
                                type="text"
                                :class="`block border border-gray-200 w-full p-2 px-4 rounded  text-xs ${errors[0] ? 'border-red-300 bg-red-50 placeholder-red-200 text-red-900 focus:border-red-300' : ''}`"
                                name="name"
                                ref="name"
                                id="name"
                                />
                            <span class="text-red-600 text-xs col-span-10">{{ errors[0] }}</span>
                            </div>
                            </ValidationProvider>

                            <ValidationProvider rules="required" v-slot="{ errors }" name="Url" vid="url" class="grid grid-cols-12 gap-4 items-center mt-4">
                            <label for="" class="block mb-2 col-span-2">Url*</label>  
                            <div class="col-span-10">
                            <input
                                v-model="addData.url"
                                type="text"
                                :class="`block border border-gray-200 w-full p-2 px-4 rounded  text-xs ${errors[0] ? 'border-red-300 bg-red-50 placeholder-red-200 text-red-900 focus:border-red-300' : ''}`"
                                name="url"
                                ref="url"
                                id="url"
                                />
                            <span class="text-red-600 text-xs col-span-10">{{ errors[0] }}</span>
                            </div>
                            </ValidationProvider>

                            <ValidationProvider rules="required" v-slot="{ errors }" name="Icon" vid="icon" class="grid grid-cols-12 gap-4 items-center mt-4">
                            <label for="" class="block mb-2 col-span-2">Icon</label>  
                            <div class="col-span-10">
                            <input
                                v-model="addData.icon"
                                type="text"
                                :class="`block border border-gray-200 w-full p-2 px-4 rounded  text-xs ${errors[0] ? 'border-red-300 bg-red-50 placeholder-red-200 text-red-900 focus:border-red-300' : ''}`"
                                name="icon"
                                ref="icon"
                                id="icon"
                                />
                            <span class="text-red-600 text-xs col-span-10">{{ errors[0] }}</span>
                            </div>
                            </ValidationProvider>
                            <ValidationProvider rules="required" v-slot="{ errors }" name="priority" vid="priority" class="grid grid-cols-12 gap-4 items-center mt-4">
                            <label for="" class="block mb-2 col-span-2">Priority</label>  
                            <div class="col-span-10">
                            <input
                                v-model="addData.priority"
                                type="text"
                                :class="`block border border-gray-200 w-full p-2 px-4 rounded  text-xs ${errors[0] ? 'border-red-300 bg-red-50 placeholder-red-200 text-red-900 focus:border-red-300' : ''}`"
                                name="priority"
                                ref="priority"
                                id="priority"
                                />
                            <span class="text-red-600 text-xs col-span-10">{{ errors[0] }}</span>
                            </div>
                            </ValidationProvider>
                             <ValidationProvider  class="grid grid-cols-12 gap-4 items-center mt-4">
                            <label for="" class="block mb-2 col-span-2">Only Developers</label>  
                            <div class="col-span-10">
                            <input type="checkbox" name="" v-model="addData.only_developers" value="1">
                            </div>
                            </ValidationProvider>
                            <ValidationProvider  vid="parent_id" class="grid grid-cols-12 gap-4 items-center mt-4">
                            <label for="" class="block mb-2 col-span-2 md:col-span-2">Parent</label>  
                            <div class="col-span-10 md:col-span-10">
                            <select ref="parent_id" name="parent_id" class="block border border-gray-200 w-full p-2 px-4 rounded text-xs" v-model="addData.parent_id" >
                                    <option value="" class="block border border-gray-200 w-full p-2 px-4 rounded text-xs">No Parent</option>
                                    <option :value="item.id" class="block border border-gray-200 w-full p-2 px-4 rounded text-xs" v-for="item in parent" :key="item.id">{{item.name}}</option>
                            </select>
                            
                            </div>
                            </ValidationProvider>
                           
                            
                            <div class="float-right">
                            <button
                                @click="$router.push('/menu')"
                                :class="`text-center py-2 px-8 rounded
                                
                                border border-gray
                                mr-2
                                hover:bg-gray-100
                                disabled:opacity-50
                                mt-4
                                
                                focus:outline-none my-1`"


                            >Batal</button>

                            <button
                                :disabled="invalid"
                                type="submit"
                                :class="`text-center py-2 px-8 rounded
                                text-white
                                hover:bg-blue-900 bg-blue-500
                                disabled:opacity-50
                                mt-4
                                
                                focus:outline-none my-1`"


                            >Simpan</button>
                            </div>

                            
                        </form>
                        </ValidationObserver>
                       </div>
                        </div>
                    </div>
                    
            </div>
        </div>
    </div>
</template>
<script>
import {mapGetters,mapAction, mapActions} from 'vuex'
import { ValidationProvider,ValidationObserver } from "vee-validate/dist/vee-validate.full";
import globalMixin from '../../mixin/global'
import moment from 'moment'
export default {
    data: () => ({
        data: [],
        totalRows:100,
        perPage:10,
        limit:5,
        currentPage:1,
        disabled:false,
        modal_dialog:false,
        keyword:'',
        foto:'',
        roles:[],
        province:[],
         device:[],
        device_id:[],
        city:[],
        district:[],
        addData:{
            email:'',
            name:'',
            company_name:'',
            phone:'',
            expired_date:'',
            foto:'',
            website:'',
            prov_id:'',
            city_id:'',
            dis_id:'',
            address:'',
            start_date:moment().format('YYYY-MM-DD').toString(),
            note:'',
        },
        delete_id:'',
        parent:[],
        modal_edit:false,
        default_url:process.env.VUE_APP_IMAGES
    }),
    mixins:[globalMixin],
    methods:{
        
      
        async postData(){
            let param = {...this.addData}
             if (this.addData.parent_id == '') {
               await delete param['parent_id']; 
            }
             if (this.addData.only_developers == '') {
               param.only_developers = 0; 
            }
            this.axios.put('v1/menu/' + this.$route.params.id,param,this.config)
            .then((ress) => {
                this.$snack.success({
                    text: ress.data.message,

                })
                this.$router.push('/menu')
            })
            .catch(err => {
                if (err.response.status == 400) {
                        this.$refs.form.setErrors(err.response.data.message)
                }
            })
           
        },
         getParent(){
            this.axios.get('v1/menu?page=' + this.currentPage + `&parent_id=1&keyword=${this.keyword}`,this.config)
            .then((ress) => {
                this.parent = ress.data.rows
            })
             .catch((err) => {
                this.errorResponse(err)
            })
        },

        async getData() {
            await this.axios.get('v1/menu/' + this.$route.params.id,this.config)
            .then(async (ress) => {
                
                this.addData = ress.data
               
            })
        },
  
    },
    computed:{
        ...mapGetters({
            token:'auth/token',
        }),
       
    },
    async created() {

        await this.getData()  
        this.getParent()
    },
    components:{
        ValidationProvider,ValidationObserver
    }
}
</script>